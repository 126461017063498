import HouseMap from './components/map/Map';
import './App.css';

function App() {
	return (
    <div className="App">
      <h1>Nashville Airbnb Cleaning Service Leads</h1>
			<HouseMap />
    </div>
  );
}

export default App;
