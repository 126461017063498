import React, { useState, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import MapIcon from '../Assets/map-icon.png';
import ListIcon from '../Assets/list-icon.png';
import './Map.css';
import List from '../list/List';
import HowItWorks from '../howItWorks/HowItWorks';

function HouseMap() {
	const [approvedHouseData, setApprovedHouseData] = useState([]);
	const [pendingHouseData, setPendingHouseData] = useState([]);
	const [listData, setListData] = useState([]);
	const [viewMap, setViewMap] = useState(true);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
	});

	useMemo(
		() => {
			fetch('https://data.nashville.gov/resource/2z82-v8pm.json?permit_status=ISSUED&&$order=date_entered DESC&&$limit=1000', {
				method: "GET",
			})
				.then((res) => res.json())
				.then((data) => {
				setApprovedHouseData(data);
			});
			fetch('https://data.nashville.gov/resource/2z82-v8pm.json?permit_status=PENDING&&$order=date_entered DESC&&$limit=1000', {
				method: "GET",
			})
				.then((res) => res.json())
				.then((data) => {
				setPendingHouseData(data);
			});
		}, []
	);
	
	function Map() {
		const [houseToView, setHouseToView] = useState(null);
		const [selectedPermits, setSelectedPermits] = useState(listData);
		const [showHowItWorks, setShowHowItWorks] = useState(false);
		const center = useMemo(() => ({lat: 36.18, lng: -86.74}), []);
		
		return (
			<>
				<div data-test-id='menu-div' className='menu' id="app-menu">
						<div onClick={() => { setViewMap(true); }} className='menu-button'>
							<img alt='map-icon' src={MapIcon} />
						</div>
						{
							selectedPermits.length > 0
								? <div onClick={() => { setViewMap(false); setListData(selectedPermits); }} className='menu-button' id='list-added'>
										<img alt='list-icon' src={ListIcon} />
										<div className='dot' />
									</div>
								: <div onClick={() => { setViewMap(false); }} className='menu-button'>
										<img alt='list-icon' src={ListIcon} />
										<div className='no-dot' />
									</div>
						}
				</div>
				<GoogleMap 
					zoom={10} 
					center={center} 
					mapContainerClassName='map-container'>
					{
						approvedHouseData.map((house) => <Marker icon={{
							url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
						}} key={house.permit} onClick={() => { setHouseToView(house); }} position={{lat: parseFloat(house.mapped_location.latitude), lng: parseFloat(house.mapped_location.longitude)}} />)
					}
					{
						pendingHouseData.map((house) => {
							return (<Marker icon={{
								url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
							}} key={house.permit} onClick={() => { setHouseToView(house); }} position={{lat: parseFloat(house.mapped_location.latitude), lng: parseFloat(house.mapped_location.longitude)}} />)
						})
					}
					{
						houseToView !== null
							? <InfoWindow
									position={{lat: parseFloat(houseToView.mapped_location.latitude), lng: parseFloat(houseToView.mapped_location.longitude)}}
									onCloseClick={() => { setHouseToView(null); }}
								>
									<div className='house-info'>
										{
											houseToView.permit_status === 'PENDING'
											  ? <h3><img alt='approved' src='http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' />{houseToView.address + `, ` + houseToView.city}</h3>
												: <h3><img alt='approved' src='http://maps.google.com/mapfiles/ms/icons/green-dot.png' />{houseToView.address + `, ` + houseToView.city}</h3>
										}
										{
											houseToView.permit_status === 'PENDING'
												? <b>PENDING</b>
												: <b>APPROVED</b>
										}
										<p><b>Applicant: </b>{houseToView.applicant}</p>
										<p><b>Applicant Address: </b>{houseToView.permit_owner_addr2 + `, ` + houseToView.permit_owner_city + `, ` + houseToView.permit_owner_state + `, ` + houseToView.permit_owner_zip}</p>
										<p><b>Permit Type: </b>{houseToView.permit_subtype_description.replace("�", "-")}</p>
										<p><b>Entered Date: </b>{houseToView.date_entered.split("T00:00:00.000")}</p>
										<p><b>Expiration Date: </b>{houseToView.expiration_date.split("T00:00:00.000")}</p>
										{
											selectedPermits.find(permit => permit.permit === houseToView.permit) === undefined
												? <button type='submit' onClick={() => { const newArray = selectedPermits.concat(houseToView); setSelectedPermits(newArray);  }}>Add to export list</button>
												: <button type='submit' disabled>Added</button>

										}
									</div>
								</InfoWindow>
							: <></>
					}
				</GoogleMap>
				<div>
					<HowItWorks show={showHowItWorks} />
					{
						showHowItWorks 
						  ? <center><button type='submit' onClick={() => { setShowHowItWorks(false); }}>Close</button></center>
						  : <center>
						  	  <p><img alt='yellow-dot' src='http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' />Permit Approval Pending</p>
							  <p><img alt='yellow-dot' src='http://maps.google.com/mapfiles/ms/icons/green-dot.png' />Permit Approved</p>
							  <button type='submit' onClick={() => { setShowHowItWorks(true); }}>How it works?</button>
							</center>
					}
				</div>

			</>
		)
	}
	
	if (!isLoaded) return <div className='map-loading'>Loading...</div>
	
	return (
		<>
			{
				viewMap
					? <Map />
					: <List houseData={listData} setMapView={setViewMap} setHousesData={setListData} />
			}
		</>
	);
}

export default HouseMap;