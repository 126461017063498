import React from 'react';
import './HowItWorks.css';

function HowItWorks({ show }) {

  if (show) {
    return (
    <center>
      <div className='how-it-works' id='how-it-works'>
        <b>
          <p>We collected the most 1,000 recent applications for</p>
          <p>'Short Term Rental Permits'</p>
          <p>These are most likely permits to list a property as an AirBnb</p>
          <p>Use the map to add leads to a download list</p>
          <p>Export the list of leads to an Excel document</p>
          <p>Utilize permit contact for mailing campaign</p>
        </b>
      </div>
    </center>
    )
  } else {
    return <></>
  }
}

export default HowItWorks;
