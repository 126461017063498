import React from 'react';
import { Table } from 'react-bootstrap';
import xlsx from "json-as-xlsx";
import MapIcon from '../Assets/map-icon.png';
import ListIcon from '../Assets/list-icon.png';
import './List.css';

function List({ houseData, setMapView, setHousesData }) {

	function download() {
		const contentData = houseData.map((house) => {
			return (
				{
					permit: house.permit,
					status: house.permit_status,
					permitAddress: house.address,
					permitCity: house.city,
					permitState: house.state,
					permitZip: house.zip,
					dateEntered: house.date_entered,
					dateIssued: house.date_issued,
					expirationDate: house.expiration_date,
					applicant: house.applicant,
					permitName: house.permit_owner_name,
					ownerAddress: house.permit_owner_addr2,
					ownerCity: house.permit_owner_city,
					ownerState: house.permit_owner_state,
					ownerZip: house.permit_owner_zip,
				}
			)
		})
		let data = [
			{
				sheet: "Instructions",
				columns: [
					{ label: "#", value: "number"},
					{ label: "To Do", value: "toDo"}
				],
				content: [
					{ number: 1, toDo: "Go to File, hover over 'more' at menu bottom"},
					{ number: 2, toDo: "Select options and a new menu should pop up"},
					{ number: 3, toDo: "Select 'Advanced' and scroll down to 'Display options for this worksheet'"},
					{ number: 4, toDo: "Select the 'Permits' worksheet in the dropdown menu"},
					{ number: 5, toDo: "Deselect the 'show sheet right to left' option and click Ok"},
					{ number: 6, toDo: "Delete this sheet"}
				]
			},
			{
				sheet: "Permits",
				columns: [
					{ label: "Permit", value: "permit" },
					{ label: "Status", value: "status"},
					{ label: "Permit Address", value: "permitAddress"},
					{ label: "Permit City", value: "permitCity"},
					{ label: "Permit State", value: "permitState"},
					{ label: "Permit Zip", value: "permitZip"},
					{ label: "Date Entered", value: "dateEntered"},
					{ label: "Date Issued", value: "dateIssued"},
					{ label: "Expiration Date", value: "expirationDate"},
					{ label: "Applicant", value: "applicant"},
					{ label: "Permit Owner Name", value: "permitName"},
					{ label: "Permit Owner Address", value: "ownerAddress"},
					{ label: "Permit Owner City", value: "ownerCity"},
					{ label: "Permit Owner State", value: "ownerState"},
					{ label: "Permit Owner Zip", value: "ownerZip"},
				],
				content: contentData,
			},
		]
		let settings = {
			fileName: "airbnb-leads", // Name of the resulting spreadsheet
			extraLength: 3, // A bigger number means that columns will be wider
			writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
			writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
			RTL: true, // Display the columns from right-to-left (the default value is false)
		}
		xlsx(data, settings)
		setHousesData([]);
	}
	
	return (
		<div>
			<div className='menu'>
				<div onClick={() => { setMapView(true); }} className='menu-button'>
					<img alt='map-icon' src={MapIcon} />
				</div>
				{
					houseData.length > 0
						? <div onClick={() => { setMapView(false); }} className='menu-button' id='list-added'>
								<img alt='list-icon' src={ListIcon} />
								<div className='dot' />
							</div>
						: <div onClick={() => { setMapView(false); }} className='menu-button'>
								<img alt='list-icon' src={ListIcon} />
								<div className='no-dot' />
							</div>
				}
			</div>
			<div>
				{
					houseData.length
						? <div className='list-button-menu'>
							<button type='submit' onClick={download}>Download</button>
							<button type='submit' onClick={() => { setHousesData([]); }}>Clear</button>
						  </div>
						: <></>
				}
			</div>
			<div>
				{
					houseData.length
						? <Table className="table" size='lg'>
							<thead>
								<tr itemScope="row">
									<th className='table-column'  itemScope="col">#</th>
									<th className='table-column' itemScope="col">Status</th>
									<th className='table-column' itemScope="col">Type</th>
									<th className='table-column' itemScope="col">Applicant</th>
									<th className='table-column' itemScope="col">Applicant Address</th>
									<th className='table-column' itemScope="col">Permit Address</th>
								</tr>
							</thead>
							<tbody>
								{
									houseData.map((house, index) => {
										return (
											<tr itemScope="row" key={house.permit}>
												<td>{index + 1}</td>
												<td>{house.permit_status}</td>
												<td>{house.permit_subtype_description.replace("�", "-")}</td>
												<td>{house.applicant}</td>
												<td>{house.permit_owner_addr2 + `, ` + house.permit_owner_city + `, ` + house.permit_owner_state + `, ` + house.permit_owner_zip}</td>
												<td>{house.address + `, ` + house.city}</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
						: <><p>No saved leads</p></>
				}
			</div>
		</div>
	)
}

export default List
